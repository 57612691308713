<template>
  <div class="container my-5">
    <h1 class="title">Reporting</h1>

    <!-- Alert for No Data -->
    <div v-if="noDataAlert" class="notification is-warning">
      No records found for the selected parameters.
    </div>

    <!-- Report Type Selection -->
    <div class="field">
      <label class="label">Select Report Type</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedReportType">
            <option value="group">Group Report</option>
            <option value="supplier">Select Supplier Report</option>
            <option value="supplier_location">
              Select Supplier and Locations Report
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Report Parameters based on Report Type -->
    <!-- Group Report -->
    <div v-if="selectedReportType === 'group'" class="box">
      <!-- Group Selection -->
      <div class="field">
        <label class="label">Select Group</label>
        <div class="control">
          <v-select
            :options="groups"
            v-model="selectedGroupId"
            label="suppliername"
            :reduce="(group) => group.supplierid"
          ></v-select>
        </div>
      </div>
      <!-- Display Selected Group -->
      <div v-if="selectedGroupId">
        <p>
          <strong>Selected Group:</strong> {{ getGroupName(selectedGroupId) }}
          <button
            class="delete is-small"
            @click="selectedGroupId = null"
          ></button>
        </p>
      </div>
    </div>

    <!-- Supplier Report -->
    <div v-else-if="selectedReportType === 'supplier'" class="box">
      <!-- Supplier Selection -->
      <div class="field">
        <label class="label">Select Suppliers</label>
        <div class="control">
          <v-select
            :options="suppliers"
            v-model="selectedSupplierIds"
            label="suppliername"
            multiple
            :reduce="(supplier) => supplier.supplierid"
          ></v-select>
        </div>
      </div>
      <!-- Display Selected Suppliers -->
      <div v-if="selectedSupplierIds.length">
        <p><strong>Selected Suppliers:</strong></p>
        <ul>
          <li v-for="supplierId in selectedSupplierIds" :key="supplierId">
            {{ getSupplierName(supplierId) }}
            <button
              class="delete is-small"
              @click="removeSelectedSupplier(supplierId)"
            ></button>
          </li>
        </ul>
      </div>
    </div>

    <!-- Supplier and Locations Report -->
    <div v-else-if="selectedReportType === 'supplier_location'" class="box">
      <!-- Supplier Selection -->
      <div class="field">
        <label class="label">Select Suppliers</label>
        <div class="control">
          <v-select
            :options="suppliersWithLocations"
            v-model="selectedSupplierWithLocationsIds"
            label="suppliername"
            multiple
            :reduce="(supplier) => supplier.supplierid"
          ></v-select>
        </div>
      </div>
      <!-- Locations Selection -->
      <div v-if="selectedSupplierWithLocationsIds.length">
        <div
          v-for="supplierId in selectedSupplierWithLocationsIds"
          :key="supplierId"
        >
          <div class="field">
            <label class="label">
              Select Locations for {{ getSupplierName(supplierId) }}
            </label>
            <div class="control">
              <v-select
                :options="getLocationsForSupplier(supplierId)"
                v-model="selectedLocationsPerSupplier[supplierId]"
                label="locationname"
                multiple
                :reduce="(location) => location.locationid"
              ></v-select>
            </div>
          </div>
        </div>
        <!-- Display Selected Locations -->
        <div>
          <p><strong>Selected Locations:</strong></p>
          <ul>
            <li
              v-for="supplierId in selectedSupplierWithLocationsIds"
              :key="supplierId"
            >
              <div
                v-if="
                  selectedLocationsPerSupplier[supplierId] &&
                  selectedLocationsPerSupplier[supplierId].length
                "
              >
                <strong>{{ getSupplierName(supplierId) }}:</strong>
                <ul>
                  <li
                    v-for="locationId in selectedLocationsPerSupplier[
                      supplierId
                    ]"
                    :key="locationId"
                  >
                    {{ getLocationName(supplierId, locationId) }}
                    <button
                      class="delete is-small"
                      @click="removeSelectedLocation(supplierId, locationId)"
                    ></button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Date Selection -->
    <div class="field is-grouped">
      <div class="control">
        <label class="label">Start Date</label>
        <input class="input" type="date" v-model="startDate" />
      </div>
      <div class="control">
        <label class="label">End Date</label>
        <input class="input" type="date" v-model="endDate" />
      </div>
    </div>

    <!-- Run Report Button -->
    <div class="field">
      <div class="control">
        <button
          class="button is-primary"
          :disabled="!canRunReport"
          @click="runReport"
        >
          Run Report
        </button>
      </div>
    </div>

    <!-- Past Reports -->
    <div class="mt-5">
      <h2 class="title is-4">Past Reports</h2>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Report Type</th>
            <th>Parameters</th>
            <th>Date Run</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in pastReports" :key="report.id">
            <td>{{ getReportTypeName(report.type) }}</td>
            <td>{{ getReportParameters(report) }}</td>
            <td>{{ formatTimestamp(report.runAt) }}</td>
            <td>
              <button
                class="button is-small is-link is-rounded"
                @click="reRunReport(report)"
              >
                Re-run
              </button>
              <button
                class="button is-small is-danger is-rounded"
                @click="confirmDeleteReport(report)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
  <script>
import { ref, computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import {
  collection,
  addDoc,
  getDocs,
  Timestamp,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/firebase/config";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "ReportingView",
  components: {
    vSelect,
  },
  setup() {
    const store = useStore();

    const selectedReportType = ref("group");

    // Start and end dates
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startDate = ref(firstDayOfMonth.toISOString().substr(0, 10));
    const endDate = ref(today.toISOString().substr(0, 10));

    // Data variables
    const groups = ref([]);
    const suppliers = ref([]);
    const suppliersWithLocations = ref([]);

    // Selected variables
    const selectedGroupId = ref(null);
    const selectedSupplierIds = ref([]);
    const selectedSupplierWithLocationsIds = ref([]);
    const selectedLocationsPerSupplier = reactive({});

    // Past Reports
    const pastReports = ref([]);
    const currentReRunReport = ref(null);
    const noDataAlert = ref(false);

    // Fetch data on mounted
    onMounted(() => {
      fetchGroups();
      fetchSuppliers();
      fetchSuppliersWithLocations();
      fetchPastReports();
    });

    // Methods to fetch data
    const fetchGroups = async () => {
      try {
        const response = await axios.post(
          `${store.state.apiUrl}/get_parent_groups`,
          {
            site_id: store.state.currentSite,
          }
        );
        groups.value = response.data;
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    const fetchSuppliers = async () => {
      try {
        const response = await axios.post(
          `${store.state.apiUrl}/get_suppliers_with_locations`,
          {
            site_id: store.state.currentSite,
          }
        );
        suppliers.value = response.data.map((supplier) => ({
          supplierid: supplier.supplierid,
          suppliername: supplier.suppliername,
        }));
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const fetchSuppliersWithLocations = async () => {
      try {
        const response = await axios.post(
          `${store.state.apiUrl}/get_suppliers_with_locations`,
          {
            site_id: store.state.currentSite,
          }
        );
        // Only include suppliers with at least one location
        suppliersWithLocations.value = response.data.filter(
          (supplier) => supplier.locations && supplier.locations.length > 0
        );
      } catch (error) {
        console.error("Error fetching suppliers with locations:", error);
      }
    };

    // Computed property to determine if the Run Report button should be enabled
    const canRunReport = computed(() => {
      if (!startDate.value || !endDate.value) {
        return false;
      }
      if (selectedReportType.value === "group" && selectedGroupId.value) {
        return true;
      } else if (
        selectedReportType.value === "supplier" &&
        selectedSupplierIds.value.length > 0
      ) {
        return true;
      } else if (
        selectedReportType.value === "supplier_location" &&
        Object.keys(selectedLocationsPerSupplier).length > 0
      ) {
        // Ensure at least one location is selected across suppliers
        return Object.values(selectedLocationsPerSupplier).some(
          (locations) => locations.length > 0
        );
      } else {
        return false;
      }
    });

    // Methods to get names
    const getGroupName = (groupId) => {
      const group = groups.value.find((g) => g.supplierid === groupId);
      return group ? group.suppliername : "";
    };

    const getSupplierName = (supplierId) => {
      const supplier =
        suppliers.value.find((s) => s.supplierid === supplierId) ||
        suppliersWithLocations.value.find((s) => s.supplierid === supplierId);
      return supplier ? supplier.suppliername : "";
    };

    const getLocationsForSupplier = (supplierId) => {
      const supplier = suppliersWithLocations.value.find(
        (s) => s.supplierid === supplierId
      );
      return supplier ? supplier.locations : [];
    };

    const getLocationName = (supplierId, locationId) => {
      const supplier = suppliersWithLocations.value.find(
        (s) => s.supplierid === supplierId
      );
      if (supplier) {
        const location = supplier.locations.find(
          (loc) => loc.locationid === locationId
        );
        return location ? location.locationname : "";
      }
      return "";
    };

    // Methods to remove selections
    const removeSelectedSupplier = (supplierId) => {
      selectedSupplierIds.value = selectedSupplierIds.value.filter(
        (id) => id !== supplierId
      );
    };

    const removeSelectedLocation = (supplierId, locationId) => {
      if (selectedLocationsPerSupplier[supplierId]) {
        selectedLocationsPerSupplier[supplierId] = selectedLocationsPerSupplier[
          supplierId
        ].filter((id) => id !== locationId);
      }
    };

    // Watch for changes in selected suppliers to maintain selected locations
    watch(selectedSupplierWithLocationsIds, (newVal, oldVal) => {
      // When suppliers are removed, remove their locations
      const removedSuppliers = oldVal.filter((id) => !newVal.includes(id));
      removedSuppliers.forEach((supplierId) => {
        delete selectedLocationsPerSupplier[supplierId];
      });
    });

    // Run Report method
    const runReport = async () => {
      noDataAlert.value = false; // Reset alert
      try {
        let response;
        if (selectedReportType.value === "group") {
          response = await axios.post(
            `${store.state.apiUrl}/generate_group_collections_report`,
            {
              group_id: selectedGroupId.value,
              start_date: startDate.value,
              end_date: endDate.value,
              report_title: "UCO Collection Report",
              group_name: getGroupName(selectedGroupId.value),
            },
            { responseType: "blob" }
          );
        } else if (selectedReportType.value === "supplier") {
          response = await axios.post(
            `${store.state.apiUrl}/generate_suppliers_uco_report`,
            {
              supplier_ids: selectedSupplierIds.value,
              start_date: startDate.value,
              end_date: endDate.value,
              report_title: "UCO Collection Report",
              group_name: "Selected Suppliers",
            },
            { responseType: "blob" }
          );
        } else if (selectedReportType.value === "supplier_location") {
          const supplierIds = selectedSupplierWithLocationsIds.value;
          const locationData = {};
          supplierIds.forEach((supplierId) => {
            locationData[supplierId] =
              selectedLocationsPerSupplier[supplierId] || [];
          });

          // Flatten location IDs and supplier IDs
          const allSupplierIds = [];
          const allLocationIds = [];
          for (const supplierId of supplierIds) {
            if (
              locationData[supplierId] &&
              locationData[supplierId].length > 0
            ) {
              allSupplierIds.push(supplierId);
              allLocationIds.push(...locationData[supplierId]);
            }
          }

          response = await axios.post(
            `${store.state.apiUrl}/generate_suppliers_locations_uco_report`,
            {
              supplier_ids: allSupplierIds,
              location_ids: allLocationIds.map((id) => parseInt(id)),
              start_date: startDate.value,
              end_date: endDate.value,
              report_title: "UCO Collection Report",
              group_name: "Selected Suppliers and Locations",
            },
            { responseType: "blob" }
          );
        }

        // Handle the PDF file
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.pdf"); // or any other extension
        document.body.appendChild(link);
        link.click();

        // Compare current parameters with currentReRunReport
        const parametersChanged =
          !currentReRunReport.value ||
          JSON.stringify(currentParameters()) !==
            JSON.stringify(currentReRunReport.value.parameters);

        if (parametersChanged) {
          // Save the report parameters in Firestore
          await saveReportParameters();
          // Fetch past reports again
          await fetchPastReports();
        }

        currentReRunReport.value = null; // Reset currentReRunReport
      } catch (error) {
        if (error.response && error.response.status === 404) {
          noDataAlert.value = true;
        } else {
          console.error("Error running report:", error);
        }
      }
    };

    const currentParameters = () => {
      const params = {
        start_date: startDate.value,
        end_date: endDate.value,
      };
      if (selectedReportType.value === "group") {
        params.group_id = selectedGroupId.value;
        params.group_name = getGroupName(selectedGroupId.value);
      } else if (selectedReportType.value === "supplier") {
        params.supplier_ids = selectedSupplierIds.value;
        params.supplier_names = selectedSupplierIds.value.map((id) =>
          getSupplierName(id)
        );
      } else if (selectedReportType.value === "supplier_location") {
        params.supplier_ids = selectedSupplierWithLocationsIds.value;
        params.locations = {};
        selectedSupplierWithLocationsIds.value.forEach((supplierId) => {
          params.locations[supplierId] =
            selectedLocationsPerSupplier[supplierId] || [];
        });
      }
      return params;
    };

    // Save report parameters in Firestore
    const saveReportParameters = async () => {
      try {
        const reportData = {
          type: selectedReportType.value,
          parameters: currentParameters(),
          runAt: Timestamp.now(),
          siteId: store.state.currentSite,
        };
        await addDoc(collection(db, "PastReports"), reportData);
      } catch (error) {
        console.error("Error saving report parameters:", error);
      }
    };

    // Fetch past reports
    const fetchPastReports = async () => {
      try {
        const q = query(
          collection(db, "PastReports"),
          where("siteId", "==", store.state.currentSite)
        );
        const querySnapshot = await getDocs(q);
        pastReports.value = [];
        querySnapshot.forEach((doc) => {
          pastReports.value.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        // Sort pastReports by runAt descending
        pastReports.value.sort(
          (a, b) => b.runAt.toMillis() - a.runAt.toMillis()
        );
      } catch (error) {
        console.error("Error fetching past reports:", error);
      }
    };

    // Get report parameters as string
    const getReportParameters = (report) => {
      if (report.type === "group") {
        return `Group: ${report.parameters.group_name || "N/A"}`;
      } else if (report.type === "supplier") {
        const supplierNames = report.parameters.supplier_names || [];
        return `Suppliers: ${supplierNames.join(", ") || "N/A"}`;
      } else if (report.type === "supplier_location") {
        const supplierIds = report.parameters.supplier_ids || [];
        const locationNames = [];
        supplierIds.forEach((supplierId) => {
          const supplierName =
            getSupplierName(supplierId) || "Unknown Supplier";
          const locations = report.parameters.locations
            ? report.parameters.locations[supplierId]
            : [];
          if (locations && locations.length > 0) {
            locations.forEach((locationId) => {
              const locationName =
                getLocationName(supplierId, locationId) || "Unknown Location";
              locationNames.push(`${supplierName} - ${locationName}`);
            });
          } else {
            locationNames.push(`${supplierName} - No Locations`);
          }
        });
        return `Locations: ${locationNames.join(", ") || "N/A"}`;
      }
      return "";
    };

    // Format timestamp to readable date
    const formatTimestamp = (timestamp) => {
      const date = timestamp.toDate();
      return date.toLocaleString();
    };

    // Get report type name
    const getReportTypeName = (type) => {
      if (type === "group") return "Group Report";
      if (type === "supplier") return "Supplier Report";
      if (type === "supplier_location") return "Supplier and Locations Report";
      return "";
    };

    // Re-run report
    const reRunReport = (report) => {
      // Set the selections based on report parameters
      selectedReportType.value = report.type;
      startDate.value = report.parameters.start_date;
      endDate.value = report.parameters.end_date;
      if (report.type === "group") {
        selectedGroupId.value = report.parameters.group_id;
      } else if (report.type === "supplier") {
        selectedSupplierIds.value = report.parameters.supplier_ids;
      } else if (report.type === "supplier_location") {
        selectedSupplierWithLocationsIds.value = report.parameters.supplier_ids;
        report.parameters.supplier_ids.forEach((supplierId) => {
          selectedLocationsPerSupplier[supplierId] =
            report.parameters.locations[supplierId] || [];
        });
      }
      currentReRunReport.value = report; // Store the current re-run report
    };

    const confirmDeleteReport = (report) => {
      if (window.confirm("Are you sure you want to delete this past report?")) {
        deleteReport(report);
      }
    };

    const deleteReport = async (report) => {
      try {
        await deleteDoc(doc(db, "PastReports", report.id));
        pastReports.value = pastReports.value.filter((r) => r.id !== report.id);
      } catch (error) {
        console.error("Error deleting report:", error);
      }
    };

    watch(
      [
        startDate,
        endDate,
        selectedGroupId,
        selectedSupplierIds,
        selectedSupplierWithLocationsIds,
        () => JSON.stringify(selectedLocationsPerSupplier),
      ],
      () => {
        noDataAlert.value = false;
      }
    );

    return {
      selectedReportType,
      startDate,
      endDate,
      groups,
      suppliers,
      suppliersWithLocations,
      selectedGroupId,
      selectedSupplierIds,
      selectedSupplierWithLocationsIds,
      selectedLocationsPerSupplier,
      getGroupName,
      getSupplierName,
      getLocationsForSupplier,
      getLocationName,
      removeSelectedSupplier,
      removeSelectedLocation,
      canRunReport,
      runReport,
      pastReports,
      getReportParameters,
      reRunReport,
      formatTimestamp,
      getReportTypeName,
      confirmDeleteReport,
      noDataAlert,
    };
  },
};
</script>
  
  <style scoped>
.container {
  max-width: 800px;
}

.box {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 2rem;
}
</style>
  