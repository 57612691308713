import { createRouter, createWebHistory } from 'vue-router'

// view Components
import Home from '@/views/Home.vue'
import Login from '@/views/Auth/Login.vue'

// Operation components
import Orders from '@/views/Operations/Orders/Orders.vue'
import OrderPdfView from '@/views/Operations/Orders/OrderPdfView.vue'
import OrderView from '@/views/Operations/Orders/OrderView.vue'
import Planning from '@/views/Operations/Planning/Planning.vue'
import RouteView from '@/views/Operations/Planning/RouteView.vue'
import ZonePlanning from '@/views/Operations/Planning/ZonePlanning.vue'

// Admin components
import Suppliers from '@/views/Administration/Suppliers/Suppliers.vue'
import Inventory from '@/views/Administration/InventoryView.vue'
import SupplierView from '@/views/Administration/Suppliers/SupplierView.vue'
import LocationView from '@/views/Administration/Suppliers/LocationView.vue'
import Trucks from '@/views/Administration/Trucks.vue'
import Route4Me from '@/views/Administration/Route4Me.vue'
import CodeGeneratorVue from '@/views/Administration/CodeGenerator.vue'

// Setting components
import Sites from '@/views/Settings/Sites.vue'
import Containers from '@/views/Settings/Containers.vue'
import Users from '@/views/Settings/Users/Users.vue'
import UserView from '@/views/Settings/Users/UserView.vue'
import Zones from '@/views/Settings/Zones/Zones.vue'
import ZoneView from '@/views/Settings/Zones/ZoneView.vue'

// Auth Components
import Signup from '@/views/Auth/Signup.vue'
import NotFound from '@/views/NotFound.vue'

import store from '@/store/index'
import Reports from '@/views/Administration/Reports.vue'




const requireAuth = (to, from, next) => {
  let user = store.state.user
  let authIsReady = store.state.authIsReady

  if (!user && authIsReady) {
    // redirect the user
    next({ name: 'Login' })
  } else {
    next()
  }
}

const requireNoAuth = (to, from, next) => {
  let authIsReady = store.state.authIsReady
  let user = store.state.user

  if (user && authIsReady) {
    // redirect the user
    next({ name: 'Orders' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireNoAuth
  },
  {
    path: '/dashboard/operations/orders',
    name: 'Orders',
    component: Orders,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/operations/order-pdf-view',
    name: 'OrderPdfView',
    component: OrderPdfView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/operations/order-view/:id/:supplierId',
    name: 'OrderView',
    props: true,
    component: OrderView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/operations/planning',
    name: 'Planning',
    component: Planning,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/operations/zone-planning',
    name: 'ZonePlanning',
    component: ZonePlanning,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/operations/route-view/:id',
    name: 'RouteView',
    props: true,
    component: RouteView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/suppliers',
    name: 'Suppliers',
    component: Suppliers,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/reports',
    name: 'Reports',
    component: Reports,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/supplier-view/:id',
    name: 'SupplierView',
    props: true,
    component: SupplierView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/location-view/:supplierId/:locationId',
    name: 'LocationView',
    props: true,
    component: LocationView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/trucks',
    name: 'Trucks',
    component: Trucks,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/inventory',
    name: 'Inventory',
    component: Inventory,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/route4me',
    name: 'Route4Me',
    component: Route4Me,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/administration/code-generator',
    name: 'CodeGenerator',
    component: CodeGeneratorVue,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/sites',
    name: 'Sites',
    component: Sites,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/containers',
    name: 'Containers',
    component: Containers,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/users',
    name: 'Users',
    component: Users,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/zones',
    name: 'Zones',
    component: Zones,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/zone-view/:id',
    name: 'ZoneView',
    props: true,
    component: ZoneView,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/settings/user-view/:id',
    name: 'UserView',
    props: true,
    component: UserView,
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/my-account',
  //   name: 'MyAccount',
  //   component: MyAccount,
  //   beforeEnter: requireAuth,
  // }

  // Catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
