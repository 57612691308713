import { createStore } from "vuex";

// firebase imports
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile
} from "firebase/auth";
import {
    doc,
    setDoc,
    Timestamp,
    onSnapshot
} from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import { watchEffect } from "vue";

const store = createStore({
    state: {
        user: null,
        currentSite: null,
        userSites: null,
        userProfile: null,
        authIsReady: false,
        currentRoute: null,
        versionNumber: 'V 1.9.0',
        // apiUrl: ' https://nf-oms-api.neutralfuels.net'
        apiUrl: 'https://oms-api.neutralfuels.net',
        // apiUrl: 'http://127.0.0.1:3100'
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
            console.log('User state changed: ', state.user)
        },
        setAuthIsReady(state, payload) {
            state.authIsReady = payload
            console.log('Auth is ready')
        },
        setCurrentSite(state, payload) {
            state.currentSite = payload
            console.log('Current Site state changed: ', state.currentSite)
        },
        setUserSites(state, payload) {
            state.userSites = payload
            console.log('User sites state changed: ', state.userSites)
        },
        setUserProfile(state, payload) {
            state.userProfile = payload
            console.log('User profile state changed: ', state.userProfile)
        },
        setCurrentRoute(state, payload) {
            state.currentRoute = payload
            console.log('Current route updated: ', state.currentRoute)
        }
    },
    actions: {
        async signup(context, { email, password, displayName, countryId, siteId }) {
            console.log('Signup action')

            // async code
            const res = await createUserWithEmailAndPassword(auth, email, password)
            if (res) {
                await updateProfile(auth.currentUser, {
                    displayName: displayName
                })

                const profileData = {
                    displayName: displayName,
                    countryId: countryId,
                    defaulltSite: siteId.siteId,
                    sites: [{ siteId: siteId.siteId, siteName: siteId.siteName }],
                    uid: res.user.uid,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                    userRole: 'ADMIN',
                    email: email,
                }
                const userRef = doc(db, 'users', res.user.uid)
                await setDoc(userRef, profileData)

                context.commit('setUser', res.user)
                context.commit('setCurrentSite', siteId)
                context.commit('setUserProfile', profileData)
            } else {
                throw Error('Could not complete signup')
            }
        },
        async login(context, { email, password }) {
            console.log('Login Action')

            // async code
            const res = await signInWithEmailAndPassword(auth, email, password)
            if (res) {
                context.commit('setUser', res.user)
                let docRef = doc(db, 'users', res.user.uid)
                const unsub2 = onSnapshot(docRef, doc => {
                    if (doc.data()) {
                        context.commit('setCurrentSite', doc.data().defaulltSite)
                        context.commit('setUserSites', doc.data().sites)
                        context.commit('setUserProfile', { ...doc.data() })
                    } else {
                        error.value = 'That docuemnt does not exsist'
                    }
                }, (err) => {
                    console.log(err.message)
                })

                watchEffect((onInvalidate) => {
                    // unsubscribe from the realtime listner
                    onInvalidate(() => unsub2())
                })
            } else {
                throw Error('Could not complete login')
            }
        },
        async logout(context) {
            console.log('Logout Action')

            // async code
            await signOut(auth)
            context.commit('setUser', null)
            context.commit('setCurrentSite', null)
            context.commit('setUserProfile', null)
        }
    }
})

// Waiting for auth and updating user state
const unsub = onAuthStateChanged(auth, (user) => {
    store.commit('setUser', user)
    store.commit('setAuthIsReady', true)

    if (user) {
        let docRef = doc(db, 'users', user.uid)

        const unsub2 = onSnapshot(docRef, doc => {
            if (doc.data()) {
                store.commit('setCurrentSite', doc.data().defaulltSite)
                store.commit('setUserSites', doc.data().sites)
                store.commit('setUserProfile', { ...doc.data() })
            } else {
                error.value = 'That docuemnt does not exsist'
            }
        }, (err) => {
            console.log(err.message)
        })

        watchEffect((onInvalidate) => {
            // unsubscribe from the realtime listner
            onInvalidate(() => unsub2())
        })
    }




    unsub()
})

export default store